import React from 'react';
import { Message } from 'semantic-ui-react';

export default () => (
  <div>
    <Message.Header>Services Offered</Message.Header>
    <Message.List>
      <Message.Item>
        We are a multi-skilled, multi-disciplined firm offering clients a wide
        range of industry-focused business solution.
      </Message.Item>
      <Message.Item>
        We offer to clients under one roof-Audit &amp; Assurance,
        Direct-Indirect Tax &amp; International Taxation, Regulatory &amp;
        Compliances and Financial advisory services etc.
      </Message.Item>
    </Message.List>
  </div>
);
