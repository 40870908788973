import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';
import ContactForm from '../Components/Contact/ContactForm';
import Address from '../Components/Contact/Address';

export default class Contact extends Component {
  render() {
    return (
      <div align='center'>
        <Message className='contactMsgClass'>
          <ContactForm />
        </Message>
        <Message className='contactMsgClass'>
          <Address />
        </Message>
      </div>
    );
  }
}
