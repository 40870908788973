import React, { Component } from 'react';

import { Icon, Menu, Sidebar } from 'semantic-ui-react';
import { BrowserRouter, NavLink, Route } from 'react-router-dom';

import Home from '../Pages/Home';
import Services from '../Pages/Services';
import Contact from '../Pages/Contact';
import { val } from '../constVal';

class Navbar extends Component {
  state = { visible: false };
  handleHideClick = () => this.setState({ visible: false });
  handleShowClick = () => this.setState({ visible: true });

  render() {
    document.title = val.firmName;

    const { visible } = this.state;

    return (
      <BrowserRouter basename="/">
        <div>
          <Menu size="huge">
            <Menu.Item>
              <img src="ca-logo.jpg" alt="logo" /> &nbsp;
              <NavLink exact to="/">
                {val.firmName}
              </NavLink>
            </Menu.Item>
            <Menu.Item onClick={this.handleShowClick}>
              <Icon name="sidebar" /> Menu
            </Menu.Item>
          </Menu>

          <Sidebar
            as={Menu}
            animation="overlay"
            inverted
            onHide={this.handleHideClick}
            vertical
            visible={visible}
            width="thin"
          >
            <Menu.Item>
              <NavLink exact to="/" onClick={this.handleHideClick}>
                <Icon name="home" />
                Home
              </NavLink>
            </Menu.Item>
            <Menu.Item>
              <NavLink to="/services" onClick={this.handleHideClick}>
                <Icon name="handshake outline" />
                Services
              </NavLink>
            </Menu.Item>
            <Menu.Item>
              <NavLink to="/contact" onClick={this.handleHideClick}>
                <Icon name="address book" />
                Contact
              </NavLink>
            </Menu.Item>
          </Sidebar>
          <div className="content">
            <Route path="/" exact component={Home} />
            <Route path="/services" component={Services} />
            <Route path="/contact" component={Contact} />
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default Navbar;
