import React from 'react';
import { Message } from 'semantic-ui-react';
import { val } from '../../constVal';

export default () => (
  <div>
    <Message.Header>About Firm</Message.Header>
    <Message.List>
      <Message.Item>
        {val.firmName} is a firm of Chartered Accountants established since
        2018.
      </Message.Item>
      <Message.Item>
        It has been providing auditing and assurance services, accounting
        services, direct, indirect and international taxation services,
        financial advisory services, registration services and regulatory &amp;
        compliance services under various statutes and acts.
      </Message.Item>
    </Message.List>
  </div>
);
