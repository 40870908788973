import React, { Component } from 'react';
import Navbar from './Components/Navbar';

class App extends Component {
  render() {
    return (
      <div>
        <Navbar />        
      </div>
    );
  }
}

export default App;
