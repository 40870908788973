import React from 'react';
import { Message } from 'semantic-ui-react';

export default () => (
  <div>
    <Message.Header>Direct Tax and Cross Border Transactions</Message.Header>
    <Message.List>
      <Message.Item>Tax Planning</Message.Item>
      <Message.Item>
        Tax Audit, Tax Compliance &amp; Reporting Services
      </Message.Item>
      <Message.Item>Data and Records Keeping</Message.Item>
      <Message.Item>Remuneration Structuring</Message.Item>
      <Message.Item>Representation Services</Message.Item>
      <Message.Item>Advance Tax Compliance</Message.Item>
      <Message.Item>Tax Return Filing</Message.Item>
      <Message.Item>TDS Compliances</Message.Item>
    </Message.List>
  </div>
);
