import React from 'react';
import { Message } from 'semantic-ui-react';

export default () => (
  <div>
    <Message.Header>Auditing &amp; Assurance</Message.Header>
    <Message.List>
      <Message.Item>
        Bank Audit (Statutory, Concurrent, Stock &amp; Revenure)
      </Message.Item>
      <Message.Item>
        Regulatory Audit (Statutory, Tax and VAT Audit)
      </Message.Item>
      <Message.Item>Internal Audit</Message.Item>
      <Message.Item>Compliance Audit</Message.Item>
      <Message.Item>Financial Statement Audit</Message.Item>
      <Message.Item>Information System Audit</Message.Item>
      <Message.Item>Due Diligence Audit</Message.Item>
      <Message.Item>Investigation Audit</Message.Item>
      <Message.Item>Certification &amp; Attestation work</Message.Item>
      <Message.Item>Transfer Pricing Audit</Message.Item>
    </Message.List>
  </div>
);
