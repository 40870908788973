import React, { Component } from 'react';
import {
  Button,
  Form,
  Input,
  TextArea,
  List,
  Message
} from 'semantic-ui-react';

const ContactGetUrl =
  'https://script.google.com/macros/s/AKfycbzLhIRW46Xwh2lbly_wksMdp6GWMRRwj4078k1lihiGP8IUKfjj/exec';

const formDefaultData = {
  name: '',
  mobile: '',
  email: '',
  city: '',
  comments: ''
};

class ContactForm extends Component {
  constructor() {
    super();
    this.state = {
      formData: formDefaultData,
      error: false,
      success: false
    };
  }

  sendMessage = () => {
    const { formData } = this.state;
    let valid = 'name' in formData ? true : false;

    for (let prop in formData) {
      if (formData[prop] === '') {
        valid = false;
        break;
      }
    }

    if (valid) {
      const getUrl = `${ContactGetUrl}?name=${formData.name}
      &mobileNumber=${formData.mobile}&emailAddress=${formData.email}
      &city=${formData.city}&comments=${formData.comments}`;
      fetch(getUrl)
        .then(() => console.log('Get in Touch - Success'))
        .catch(() => console.log('Get in Touch - Fail'));

      this.setState({
        success: true,
        error: false,
        formData: formDefaultData
      });
      setTimeout(() => this.setState({ success: false, error: false }), 2000);
    } else {
      this.setState({ error: true });
    }
  };

  onChangeFormElement = e => {
    const { formData } = this.state;
    this.setState({
      formData: { ...formData, [e.target.name]: e.target.value }
    });
  };

  render() {
    const { formData, error, success } = this.state;
    return (
      <List>
        <List.Item>
          <List.Content>
            <Form>
              <Form.Field
                id='form-button-control-public'
                content='Confirm'
                label='Write to Us'
              />
              <Form.Group widths='equal'>
                <Form.Field
                  name='name'
                  control={Input}
                  label='Name'
                  placeholder='Name'
                  value={formData.name}
                  onChange={this.onChangeFormElement}
                />
                <Form.Field
                  name='mobile'
                  control={Input}
                  type='number'
                  label='Mobile Number'
                  placeholder='Mobile Number'
                  value={formData.mobile}
                  onChange={this.onChangeFormElement}
                />
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Field
                  name='email'
                  control={Input}
                  label='Email Address'
                  placeholder='Email Address'
                  value={formData.email}
                  onChange={this.onChangeFormElement}
                />
                <Form.Field
                  name='city'
                  control={Input}
                  label='City'
                  placeholder='City'
                  value={formData.city}
                  onChange={this.onChangeFormElement}
                />
              </Form.Group>

              <Form.Field
                name='comments'
                control={TextArea}
                label='Comments'
                placeholder='Comments'
                value={formData.comments}
                onChange={this.onChangeFormElement}
              />
              {error && (
                <Message
                  color='orange'
                  content='Please provide above information.'
                />
              )}
              {success && (
                <Message color='olive' content='We will contact you soon.' />
              )}
              <Form.Group>
                <Form.Field
                  control={Button}
                  content='Send Message'
                  onClick={this.sendMessage}
                />
              </Form.Group>
            </Form>
          </List.Content>
        </List.Item>
      </List>
    );
  }
}

export default ContactForm;
