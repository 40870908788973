import React from 'react';
import { Message } from 'semantic-ui-react';

export default () => (
  <div>
    <Message.Header>Indirect Tax</Message.Header>
    <Message.List>
      <Message.Item>Advisory and Opinions</Message.Item>
      <Message.Item>Compliance and Support</Message.Item>
      <Message.Item>Indirect Tax Review</Message.Item>
      <Message.Item>GST and IGST</Message.Item>
      <Message.Item>Refund and Rebate</Message.Item>
      <Message.Item>Representation</Message.Item>
      <Message.Item>Strategic Consulting</Message.Item>
    </Message.List>
  </div>
);
