import React, { Component } from 'react';
import { Message, Table } from 'semantic-ui-react';
import AuditingAssurance from '../Components/Services/AuditingAssurance';
import DirectTaxCrossBorderTrans from '../Components/Services/DirectTaxCrossBorderTrans';
import RegulatoryCompliances from '../Components/Services/RegulatoryCompliances';
import IndirectTax from '../Components/Services/IndirectTax';

export default class Services extends Component {
  render() {
    return (
      <div align="center">
        <Table celled>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Message>
                  <AuditingAssurance />
                </Message>
              </Table.Cell>
              <Table.Cell>
                <Message>
                  <DirectTaxCrossBorderTrans />
                </Message>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Message>
                  <RegulatoryCompliances />
                </Message>
              </Table.Cell>
              <Table.Cell>
                <Message>
                  <IndirectTax />
                </Message>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }
}
