import React from 'react';
import ReactDOM from 'react-dom';
import './static/index.css';
import 'semantic-ui-css/semantic.min.css';
import App from './App';

if (window.location.hostname !== 'localhost') {
  let tagScript = document.createElement('script');
  tagScript.setAttribute("async","");
  tagScript.src = 'https://www.googletagmanager.com/gtag/js?id=UA-135007783-1';
  document.getElementsByTagName('head')[0].appendChild(tagScript);

  let layerScript = document.createElement('script');
  layerScript.innerHTML = `window.dataLayer = window.dataLayer || [];  function gtag() {    dataLayer.push(arguments);  }  gtag('js', new Date());  gtag('config', 'UA-135007783-1');`;
  document.getElementsByTagName('head')[0].appendChild(layerScript);
}
ReactDOM.render(<App />, document.getElementById('root'));
