import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';
import AboutFirm from '../Components/Home/AboutFirm';
import ValuesVision from '../Components/Home/ValuesVision';
import ServicesOffered from '../Components/Home/ServicesOffered';

export default class Home extends Component {
  render() {
    return (
      <div align="center">
        <Message className="homeMsgClass">
          <AboutFirm />
          <br />
          <ValuesVision />
          <br />
          <ServicesOffered />
        </Message>
      </div>
    );
  }
}
