import React from 'react';
import { Message } from 'semantic-ui-react';

export default () => (
  <div>
    <Message.Header>Regulatory &amp; Compliances Services</Message.Header>
    <Message.List>
      <Message.Item>Business Licensing Services</Message.Item>
      <Message.Item>ROC Compliances and Secretarial work</Message.Item>
      <Message.Item>Dispute &amp; Litigation Matters</Message.Item>
      <Message.Item>Entity Incorporation</Message.Item>
      <Message.Item>
        Valuation &amp; Verification of Fixed Assets / Inventory / Securities
      </Message.Item>
      <Message.Item>
        Registration and compliance of industry specific statutes
      </Message.Item>
      <Message.Item>IFRS Conversion</Message.Item>
    </Message.List>
  </div>
);
