import React from "react";
import { List } from "semantic-ui-react";
import { val } from "../../constVal";

export default () => (
  <div>
    <List>
      <List.Item>
        <List.Icon name="users" />
        <List.Content>
          <b>{val.firmName}</b>
          <br />
          Chartered Accountants
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Icon name="marker" />
        <List.Content>
          Sathariya,
          <br /> Opp. Sathariya Govt. Hospital,
          <br />
          Post Office - Panwara,
          <br /> Jaunpur - 222202 (U.P.)
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Icon name="mobile" />
        <List.Content>+91 - 900 486 8959</List.Content>
      </List.Item>
      <List.Item>
        <List.Icon name="mail" />
        <List.Content>
          <a href="mailto:info@shaileshca.com">info@shaileshca.com</a>
        </List.Content>
      </List.Item>
      <br />
      <List.Item>
        <List.Content>
          <List.Icon name="code" />
          Developed by &nbsp;
          <a
            href="http://santosh-yadav.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            santosh-yadav.com
          </a>
        </List.Content>
      </List.Item>
    </List>
  </div>
);
